import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import YounionLinkList from "../components/YounionLinkList"
import ImagePopup from "../components/ImagePopup"

export default props => {
  const { data, pageContext } = props
  const { younion, younionLogo } = data
  const { newsItems } = younion
  const { _id } = pageContext

  const news = newsItems.find(item => item._id === _id)
  const {
    header,
    subheader,
    webcontent,
    images,
    membersignin,
    links,
    linksForMembers,
    downloads,
    downloadsForMembers,
    files,
    filesForMembers,
  } = news

  const [selectedImage, setSelectedImage] = useState(0)
  const [popupOpen, setPopupOpen] = useState(false)

  const moveImageLeft = () =>
    selectedImage < 1
      ? setSelectedImage(0)
      : setSelectedImage(selectedImage - 1)
  const moveImageRight = () =>
    selectedImage >= images.length - 1
      ? setSelectedImage(images.length - 1)
      : setSelectedImage(selectedImage + 1)

  return (
    <div>
      <div className="detail detail-notDotted">
        <div className="detail-left">
          {images.length ? (
            <div className="detail-image-box">
              {selectedImage > 0 ? (
                <div
                  className="detail-arrow-left"
                  role="button"
                  tabIndex={0}
                  onClick={moveImageLeft}
                  onKeyPress={moveImageLeft}
                >
                  <svg width="12.5" height="12.5">
                    <polygon
                      points="12.5,0 0,6.25 12.5,12.5"
                      style={{ fill: "white" }}
                    />
                  </svg>
                </div>
              ) : null}
              {selectedImage < images.length - 1 ? (
                <div
                  className="detail-arrow-right"
                  role="button"
                  tabIndex={0}
                  onClick={moveImageRight}
                  onKeyPress={moveImageRight}
                >
                  <svg width="12.5" height="12.5">
                    <polygon
                      points="0,0 12.5,6.25 0,12.5"
                      style={{ fill: "white" }}
                    />
                  </svg>
                </div>
              ) : null}
              <div
                className="detail-image-popup"
                role="button"
                tabIndex={0}
                onClick={() => setPopupOpen(true)}
                onKeyPress={() => setPopupOpen(true)}
              >
                <Img
                  fluid={images[selectedImage].image.childImageSharp.fluid}
                  alt={images[selectedImage].title}
                  objectFit="none"
                  className="detail-image"
                />
              </div>
            </div>
          ) : (
              <Img
                fluid={younionLogo.childImageSharp.fluid}
                objectFit="none"
                className="detail-image"
              />
            )}
          <ImagePopup
            open={popupOpen}
            setOpen={setPopupOpen}
            images={images}
            defaultId={selectedImage}
          />
          {membersignin ?
            <div className="younionlink">
              <Link to="/signin">Hier registrieren</Link>
            </div> : null}
          <YounionLinkList
            className="younionlink"
            links={links}
            linksForMembers={linksForMembers}
            title="Links"
          />
          <YounionLinkList
            className="younionlink"
            links={downloads}
            linksForMembers={downloadsForMembers}
            title="Downloads"
            download={true}
          />
          <YounionLinkList
            className="younionlink"
            links={files}
            linksForMembers={filesForMembers}
            title="Dateien"
            files={true}
            download={true}
          />
        </div>
        <div className="detail-right">
          <h1>{header}</h1>
          <h2>{subheader}</h2>
          <div dangerouslySetInnerHTML={{ __html: webcontent }} />

          {membersignin ?
            <div className="mobile-younionlink">
              <Link to="/signin">Hier registrieren</Link>
            </div> : null}
          <YounionLinkList
            className="mobile-younionlink"
            links={links}
            linksForMembers={linksForMembers}
            title="Links"
          />
          <YounionLinkList
            className="mobile-younionlink"
            links={downloads}
            linksForMembers={downloadsForMembers}
            title="Downloads"
            download={true}
          />
          <YounionLinkList
            className="younionlink"
            links={files}
            linksForMembers={filesForMembers}
            title="Dateien"
            files={true}
            download={true}
          />
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  query {
    younion {
      newsItems {
        ...newsItemFields
      }
    }

    younionLogo: file(relativePath: { eq: "younion_logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
